<template>
    <div>
        <md-dialog :md-active.sync="show" class="maschine-fingerprint" style="padding: 14pt; " v-on:close="$router.go(-1)" v-on:md-clicked-outside="$router.go(-1)">
            <div class="horizontal-wrapper" style="padding: 24px 24px 0;">
                <md-dialog-title style="padding: 0; margin: auto">Maschinen Fingerprint</md-dialog-title>
                <div></div>
                <md-button v-on:click="copyToClipboard">Copy to Clipboard</md-button>
            </div>
            <div class="vertical-wrapper" style="margin: 14pt; ">
                <md-tabs :md-dynamic-height="true" v-on:md-changed="tabChanged">
                    <md-tab :id="0" md-label="Fingerprint">
                        <md-field>
                            <md-textarea v-model="fingerprint" :disabled="true"  md-autogrow></md-textarea>
                        </md-field>
                    </md-tab>

                    <md-tab v-for="i, index in installerScripts" :id="index + 1" :md-label="i.name" :key="i.key">
                        <md-field>
                            <md-textarea :value="maschineIdentification + i.script" :disabled="true"  md-autogrow></md-textarea>
                        </md-field>
                    </md-tab>

                   
                </md-tabs>

              
            </div>
            
        </md-dialog>
        <md-snackbar :md-duration="2000" :md-active.sync="showSnackbar" md-persistent>
            <span>Copied to Clipboard!</span>
            <md-button class="md-primary" @click="showSnackbar = false">Close</md-button>
        </md-snackbar>
        </div>

</template>
<script>
import { uuid } from 'vue-uuid'

export default {
    name: 'maschine-fingerprint-dialog',
    props: { id:[String, Number], mad: String },
    methods: {
        save(){
          
        },
        tabChanged(e){
            this.tabId = e
        },
        async copyToClipboard() {
            let copyText;

            console.log(this.tabId);
            if(this.tabId == 0){
                copyText = this.fingerprint
            }else{
                const index = this.tabId -1 

                copyText = this.maschineIdentification + this.installerScripts[index].script
            }

            try {
                await navigator.clipboard.writeText(copyText);
                this.showSnackbar = true
            } catch (err) {
                console.error('Failed to copy text: ', err);
            }
        },
    },
    beforeRouteEnter (to, from, next) { 
        next(vm => { 
            vm.show = true
            // request all links and installation scripts for this mandate
            fetch(`${vm.$hostname}/settings/maschines/installation`, {
                    method: 'POST', 
                    headers: { authorization: vm.token, "Content-Type": "application/json", },
                    body: JSON.stringify({})
            }).then(async (e) => {
                if(e.status == 200){
                    const installationScripts = (await e.json()).installationScripts
                    installationScripts.forEach(e => e.key = uuid.v4())

                    vm.installerScripts = installationScripts;
                }

            }).catch()

            // get fingerprint of this instance
            const body = JSON.stringify({maschine: {id: vm.$props.id}})
            fetch(`${vm.$hostname}/settings/maschines/fingerprint`, {
                    method: 'POST', 
                    headers: { authorization: vm.token, "Content-Type": "application/json", },
                    body
            }).then(async (e) => {
                if(e.status == 200)
                    vm.fingerprint = (await e.json()).fingerprint

            }).catch()
            next();
        }) 
    },
    sockets:{
        

    },
    computed:{
        token(){
            return this.$store.getters.token
        },
        maschineIdentification(){
            const installationScript = `FINGERPRINT=${this.fingerprint} MAD="${this.mad}" `
            return installationScript
        }

     
    },
    data(){
        return{
            show: false,
            fingerprint: '',
            showSnackbar: false,

            installerScripts: [
                {key: this.$uuid.v4(), name: 'IotAdapter', script: `bash -c 'curl https://raw.githubusercontent.com/AndreasScharf/IotAdapter/master/installer.sh | bash -s -- standalone'`}, 

                            


            ],


            tabId: 0
        }
    }
}
</script>
<style>
.maschine-fingerprint.md-dialog .md-dialog-container{
    min-width: 60%; min-width: 50%;
}
</style>